<template>
  <div v-if="isLoading" class="position-relative">
    <spinner-component item-class="spinner-layout-gray"></spinner-component>
  </div>
  <div v-if="!isLoading">
    <div class="page-header-title">
      <h3 class="m-b-10">{{ $t('buyConversationApi') }}</h3>
    </div>
    <div class="nav-pills">
      <a
        v-for="(tab, index) in tabsConversationApi"
        href="#"
        :key="index"
        :class="isActiveConversationTab(tab) ? 'active' : ''"
        @click.prevent="activateConversationTabHandler(tab)"
      >{{ $t(`purchasedTabs.${tab}`) }}</a>
    </div>
    <div v-if="isActiveConversationTab('conversation_kits')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.conversation_kits`) }}</h5>
      </div>
      <div class="card-block">
        <div v-if="isShowSenderNameForm && selectedPackage" class="mb-2 text-right">
          <button class="btn btn-sm btn-light" @click="cancelSenderNameFormHandler">
            <i class="feather icon-x mx-1"></i>{{ $t('cancel') }}
          </button>
        </div>
        <sender-name-form
          v-if="isShowSenderNameForm && selectedPackage"
          :plan="selectedPackage"
        ></sender-name-form>
        <buy-conversations-kit-form
          v-if="!isShowSenderNameForm"
          :packages="senderKitsPackages"
          @select-package="selectPackageHandler"
        ></buy-conversations-kit-form>
      </div>
    </div>
    <div v-if="isActiveConversationTab('packages')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.packages`) }}</h5>
      </div>
      <div class="card-block">
        <message-package-form :packages="packages"></message-package-form>
      </div>
    </div>
    <div v-if="isActiveConversationTab('package_subscriptions')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.messagesPlans`) }}</h5>
      </div>
      <div class="card-block">
        <message-package-form :packages="packageSubscriptions"></message-package-form>
      </div>
    </div>
    <div v-if="isActiveConversationTab('sender_name')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.sender_name`) }}</h5>
      </div>
      <div class="card-block">
        <div v-if="isShowDedicatedSenderNameForm && selectedDedicatedPackage" class="mb-2 text-right">
          <button class="btn btn-sm btn-light" @click="cancelDedicatedSenderNameFormHandler">
            <i class="feather icon-x mx-1"></i>{{ $t('cancel') }}
          </button>
        </div>
        <sender-name-form
          v-if="isShowDedicatedSenderNameForm && selectedDedicatedPackage"
          :plan="selectedDedicatedPackage"
        ></sender-name-form>
        <buy-sender-name-form
          v-if="!isShowDedicatedSenderNameForm"
          :plan="senderNamePlan"
          @select-package="selectDedicatedSenderNameHandler"
        ></buy-sender-name-form>
      </div>
    </div>
<!--    <div v-if="isActiveConversationTab('phone_numbers')" class="card collapse show">-->
<!--      <div class="card-header d-flex justify-content-between align-items-center">-->
<!--        <h5>{{ $t(`purchasedTabs.phone_numbers`) }}</h5>-->
<!--      </div>-->
<!--      <div class="card-block">-->
<!--        <div v-if="isShowPhoneNumberAliasForm && selectedPhoneNumberSender" class="mb-2 text-right">-->
<!--          <button class="btn btn-sm btn-light" @click="cancelPhoneNumberFormHandler">-->
<!--            <i class="feather icon-x mx-1"></i>{{ $t('cancel') }}-->
<!--          </button>-->
<!--        </div>-->
<!--        <phone-number-alias-form-->
<!--          v-if="isShowPhoneNumberAliasForm && selectedPhoneNumberSender"-->
<!--          :sender_name="selectedPhoneNumberSender"-->
<!--        ></phone-number-alias-form>-->
<!--        <buy-phone-number-alias-form-->
<!--          v-if="!isShowPhoneNumberAliasForm"-->
<!--          :phone_numbers="phoneNumberAlias"-->
<!--          @select-package="selectPhoneNumberAliasHandler"-->
<!--        ></buy-phone-number-alias-form>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div v-if="isLoadingLookup" class="position-relative">
    <spinner-component item-class="spinner-layout-gray"></spinner-component>
  </div>
  <div v-if="!isLoadingLookup">
    <div class="page-header-title">
      <h3 class="m-b-10">iPhone lookup API</h3>
    </div>
    <div class="nav-pills">
      <a
        v-for="(tab, index) in tabsLookupApi"
        href="#"
        :key="index"
        :class="isActiveLookupTab(tab) ? 'active' : ''"
        @click.prevent="activateLookupTabHandler(tab)"
      >{{ $t(`purchasedTabs.${tab}`) }}</a>
    </div>
    <div v-if="isActiveLookupTab('packages')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.packages`) }}</h5>
      </div>
      <div class="card-block">
        <lookup-message-package-form :packages="lookupPackages"></lookup-message-package-form>
      </div>
    </div>
    <div v-if="isActiveLookupTab('subscription_plans')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.subscription_plans`) }}</h5>
      </div>
      <div class="card-block">
        <lookup-message-package-form :packages="lookupSubscriptions"></lookup-message-package-form>
      </div>
    </div>
  </div>
  <div v-if="isLoadingAuthRequest" class="position-relative">
    <spinner-component item-class="spinner-layout-gray"></spinner-component>
  </div>
  <div v-if="!isLoadingAuthRequest">
    <div class="page-header-title">
      <h3 class="m-b-10">{{ $t('buyAuthRequestApi') }}</h3>
    </div>
    <div class="nav-pills">
      <a
        v-for="(tab, index) in tabsAuthRequestApi"
        href="#"
        :key="index"
        :class="isActiveAuthRequstTab(tab) ? 'active' : ''"
        @click.prevent="activateAuthRequestHandler(tab)"
      >{{ $t(`purchasedTabs.authRequest.${tab}`) }}</a>
    </div>
    <div v-if="isActiveAuthRequstTab('packages')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t(`purchasedTabs.packages`) }}</h5>
      </div>
      <div class="card-block">
        <auth-requests-package-form :packages="authRequestPackages"></auth-requests-package-form>
      </div>
    </div>
    <div v-if="isActiveAuthRequstTab('subscription_plans')" class="card collapse show">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5>{{ $t('purchasedTabs.authRequest.subscription_plans') }}</h5>
      </div>
      <div class="card-block">
        <auth-requests-package-form :packages="authRequestSubscriptions"></auth-requests-package-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineAsyncComponent, onMounted } from 'vue'
import { getPackages } from '@/application/services/message-packages'
import { getLookupPackages } from '@/application/services/lookup-api'
import { getAuthRequestPackages } from '@/application/services/auth-request-packages.js'
// sendAuthRequestPaddleLink
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'
import { initPaddle } from '@/application/utils/paddle'
import { usePillsTabLogic } from '@/application/composables/logicPillsTab'

export default {
  name: 'MessagingPage',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    'message-package-form': defineAsyncComponent(() => import('@/application/components/forms/MessagePackagesForm.vue')),
    'lookup-message-package-form': defineAsyncComponent(() => import('@/application/components/forms/LookupMessagePackagesForm.vue')),
    'auth-requests-package-form': defineAsyncComponent(() => import('@/application/components/forms/AuthRequestPackagesForm')),
    'buy-conversations-kit-form': defineAsyncComponent(() => import('@/application/components/forms/BuyConversationsKitForm.vue')),
    'sender-name-form': defineAsyncComponent(() => import('@/application/components/pages/NewSenderName.vue')),
    'buy-sender-name-form': defineAsyncComponent(() => import('@/application/components/forms/BuySenderNameForm.vue')),
    // 'buy-phone-number-alias-form': defineAsyncComponent(() => import('@/application/components/forms/BuyPhoneNumberAliasForm.vue')),
    // 'phone-number-alias-form': defineAsyncComponent(() => import('@/application/components/pages/NewPhoneNumberAlias.vue')),
  },
  setup() {
    const { t } = useI18n()
    const { openModal } = useModal()
    const senderKitsPackages = ref([])
    const packages = ref([])
    const selectedPackage = ref(null)
    const selectedDedicatedPackage = ref(null)
    const selectedPhoneNumberSender = ref(null)
    const packageSubscriptions = ref([])
    const senderNamePlan = ref({})
    const phoneNumberAlias = ref({})
    const plans = ref([])
    const lookupPackages = ref([])
    const lookupSubscriptions = ref([])
    const authRequestPackages = ref([])
    const authRequestSubscriptions = ref([])
    const checkoutCompleted = ref(false)
    const showAlertSuccess = ref(true)
    const isLoading = ref(true)
    const isLoadingLookup = ref(true)
    const isLoadingAuthRequest = ref(true)
    const isShowSenderNameForm = ref(false)
    const isShowDedicatedSenderNameForm = ref(false)
    const isShowPhoneNumberAliasForm = ref(false)

    const {
      activateTabHandler: activateConversationTabHandler,
      isTabActive: isActiveConversationTab,
    } = usePillsTabLogic()
    const {
      activateTabHandler: activateLookupTabHandler,
      isTabActive: isActiveLookupTab,
    } = usePillsTabLogic()
    const {
      activateTabHandler: activateAuthRequestHandler,
      isTabActive: isActiveAuthRequstTab,
    } = usePillsTabLogic()

    const tabsConversationApi = ref([])
    const allowedTabs = ['conversation_kits', 'packages', 'package_subscriptions', 'sender_name']
    const allowedLookupTabs = ['packages', 'subscription_plans']
    const tabsLookupApi = ref([])
    const allowedAuthRequestTabs = ['packages', 'subscription_plans']
    const tabsAuthRequestApi = ref([])

    const selectPackageHandler = (packageObj) => {
      selectedPackage.value = packageObj
      isShowSenderNameForm.value = true
    }
    const cancelSenderNameFormHandler = () => {
      selectedPackage.value = null
      isShowSenderNameForm.value = false
    }
    const selectDedicatedSenderNameHandler = (plan) => {
      selectedDedicatedPackage.value = plan
      isShowDedicatedSenderNameForm.value = true
    }
    const selectPhoneNumberAliasHandler = (plan) => {
      selectedPhoneNumberSender.value = plan
      isShowPhoneNumberAliasForm.value = true
    }
    const cancelDedicatedSenderNameFormHandler = () => {
      selectedDedicatedPackage.value = null
      isShowDedicatedSenderNameForm.value = false
    }
    const cancelPhoneNumberFormHandler = () => {
      selectedPhoneNumberSender.value = null
      isShowPhoneNumberAliasForm.value = false
    }

    onMounted(async() => {
      const { data } = await getPackages()
      isLoading.value = false
      data.items.forEach(element => {
        const objectKey = Object.keys(element)[0]
        if (allowedTabs.includes(objectKey)) {
          tabsConversationApi.value.push(objectKey)
        }
        if (objectKey === 'conversation_kits') {
          senderKitsPackages.value = element[objectKey]
        } else if (objectKey === 'packages') {
          packages.value = element[objectKey]
        } else if (objectKey === 'package_subscriptions') {
          packageSubscriptions.value = element[objectKey]
        } else if (objectKey === 'sender_name') {
          senderNamePlan.value = element[objectKey]
        } else if (objectKey === 'phone_numbers') {
          phoneNumberAlias.value = element[objectKey]
        }
      })

      const { data: lookupData } = await getLookupPackages()
      isLoadingLookup.value = false
      lookupData.items.forEach(element => {
        const objectKey = Object.keys(element)[0]
        if (allowedLookupTabs.includes(objectKey)) {
          tabsLookupApi.value.push(objectKey)
        }
        if (objectKey === 'packages') {
          lookupPackages.value = element[objectKey]
        } else if (objectKey === 'subscription_plans') {
          lookupSubscriptions.value = element[objectKey]
        }
      })

      const { data: authRequestPackagesData } = await getAuthRequestPackages()
      isLoadingAuthRequest.value = false
      authRequestPackagesData.items.forEach(element => {
        const objectKey = Object.keys(element)[0]
        if (allowedAuthRequestTabs.includes(objectKey)) {
          tabsAuthRequestApi.value.push(objectKey)
        }
        if (objectKey === 'packages') {
          authRequestPackages.value = element[objectKey]
        } else if (objectKey === 'subscription_plans') {
          authRequestSubscriptions.value = element[objectKey]
        }
      })

      activateConversationTabHandler(tabsConversationApi.value[0])
      activateLookupTabHandler(tabsLookupApi.value[0])
      activateAuthRequestHandler(tabsAuthRequestApi.value[0])
    })

    initPaddle(() => {
      window.Paddle.Setup(function(data) {
        if (data.event === 'Checkout.PaymentComplete' && !data.eventData.flagged) {
          checkoutCompleted.value = true
        } else if (data.event === 'Checkout.Close') {
          if (checkoutCompleted.value === true) {
            openModal(t('success'), t('forms.buyPackages.successMessage'))
          }
          checkoutCompleted.value = false
        }
      })
    })

    return {
      isShowSenderNameForm,
      isLoading,
      isLoadingLookup,
      selectedPackage,
      senderKitsPackages,
      packages,
      packageSubscriptions,
      senderNamePlan,
      phoneNumberAlias,
      plans,
      showAlertSuccess,
      tabsConversationApi,
      activateConversationTabHandler,
      isActiveConversationTab,
      selectPackageHandler,
      cancelSenderNameFormHandler,
      selectDedicatedSenderNameHandler,
      selectPhoneNumberAliasHandler,
      cancelDedicatedSenderNameFormHandler,
      cancelPhoneNumberFormHandler,
      selectedDedicatedPackage,
      selectedPhoneNumberSender,
      isShowDedicatedSenderNameForm,
      isShowPhoneNumberAliasForm,
      activateLookupTabHandler,
      isActiveLookupTab,
      tabsLookupApi,
      lookupPackages,
      lookupSubscriptions,
      activateAuthRequestHandler,
      isActiveAuthRequstTab,
      tabsAuthRequestApi,
      authRequestPackages,
      authRequestSubscriptions,
      isLoadingAuthRequest,
    }
  },
}
</script>
